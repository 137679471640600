import React from 'react';
import styled from 'styled-components';
import {
  Brandbar,
  ContactModule,
  Footer,
  Header,
  Icon,
  IconLink,
  Metabar,
  Themes,
  Link,
} from '@lsg/components';

type props = {
  children: React.ReactNode | React.ReactNodeArray | HTMLBaseElement;
};

const PageLayout = ({ children }: props) => (
  <Page>
    <Header
      segmentLabel={'Für den Partnervertrieb'}
      logoHref={'/'}
      className={'noNavBar'}
    />
    <PageContent>{children}</PageContent>
    <Footer
      theme={Themes.DARK}
      previousTheme={Themes.LIGHT}
      contactArea={
        <ContactModule
          phoneNumber={'+49 69 136 45151'}
          phoneText={
            'Für alle Fragen stehen wir Ihnen persönlich zur Verfügung.'
          }
        >
          <IconLink
            iconName={Icon.Names.communication___envelope}
            look={IconLink.Looks && IconLink.Looks.CIRCLE}
            href="mailto:info-fondsvertrieb@commerzbank.com"
          >
            Mail
          </IconLink>
          <IconLink
            iconName={Icon.Names.location___location}
            look={IconLink.Looks && IconLink.Looks.CIRCLE}
            href="https://www.google.com/maps/place/Commerzbank+AG/@50.10967,8.67084,15z/data=!4m2!3m1!1s0x0:0xd5bef259849643d4?sa=X&ved=2ahUKEwjH-IHn4JznAhWEL1AKHdreBMkQ_BIwE3oECA4QCA"
          >
            Standort
          </IconLink>
        </ContactModule>
      }
    >
      <Brandbar
        slogan={'Die Bank an Ihrer Seite'}
        href={'https://www.commerzbank.de/'}
      />
      <Metabar>
        <Metabar.MetaLinks>
          <Link
            target={'_blank'}
            rel={'noopener'}
            href={
              'https://www.commerzbank.de/portal/de/footer1/impressum/impressum_1.html'
            }
          >
            Impressum
          </Link>
          <Link
            target={'_blank'}
            rel={'noopener'}
            href={
              'https://www.commerzbank.de/portal/de/footer1/recht/rechtliche_hinweise.html'
            }
          >
            Recht
          </Link>
          <Link
            target={'_blank'}
            rel={'noopener'}
            href={
              'https://www.commerzbank.de/portal/de/privatkunden/hilfe-kontakt/services/sicherheit-onlinebanking/sicherheit-onlinebanking.html'
            }
          >
            Sicherheit
          </Link>
        </Metabar.MetaLinks>
      </Metabar>
    </Footer>
  </Page>
);

export default PageLayout;

const Page = styled.div`
  overflow-x: hidden;
`;
const PageContent = styled.div``;
