import React, { useState, useEffect, useCallback } from 'react';
import { graphql } from 'gatsby';
import { Section, Tabs, H2, Grid } from '@lsg/components';
import { GridRow } from '@lsg/components/lib/components/atoms/Grid/GridRow';
import { GridColumn } from '@lsg/components/lib/components/atoms/Grid/GridColumn';
import { ContentAccessor } from '@lws/react-components';
import {
  ContentfulProductDetail,
  ContentfulProductDetailSection,
} from '../../generated/graphql-types';
import { ProductDetail } from './ProductDetail';
import { MobileProductDetail } from './MobileProductDetail';
import { useViewportSize } from '@lws/page-components/dist/utils';

interface ProductDetailSectionProps {
  data: ContentAccessor<ContentfulProductDetailSection>;
  location: any;
}

export const ProductDetailSection = ({
  data,
  location,
}: ProductDetailSectionProps) => {
  // const title = data.text('title');
  const productsDetailCollection = data.array('productsDetailCollection');
  const [activeIndex, setIndex] = useState(0);
  const viewport = useViewportSize();
  const getActiveIndex = useCallback(
    () =>
      typeof window !== 'undefined' && window.location.hash.length
        ? productsDetailCollection.findIndex(
            el =>
              window.location.hash.replace('#', '') ===
              el.slug('productLocation', '')
          )
        : 0,
    [productsDetailCollection, activeIndex, viewport]
  );

  useEffect(() => {
    setIndex(getActiveIndex());
  }, []);

  return (
    <Section>
      {/* <GridRow>
        <GridColumn xs={12} sm={12} md={8} lg={5} xl={5}>
          <H2>{title}</H2>
        </GridColumn>
      </GridRow> */}
      <GridRow gap={Grid.Spacing?.HALF_SECTION}>
        {/* product detail section rendered for larger breakpoints */}
        {(viewport === 'md' || viewport === 'lg' || viewport === 'xl') && (
          <GridColumn xs={0} sm={0} md={12} lg={12} xl={12}>
            {productsDetailCollection && productsDetailCollection.length > 1 ? (
              <Tabs
                openIndex={activeIndex}
                onChange={index => {
                  setIndex(index);
                  history.pushState(
                    '',
                    document.title,
                    `#${productsDetailCollection[index].slug(
                      'productLocation',
                      ''
                    )}`
                  );
                }}
              >
                {productsDetailCollection.map(
                  (
                    productDetail: ContentAccessor<ContentfulProductDetail>,
                    idx: number
                  ) => {
                    const tabLabel = productDetail.text('productLocation', '');
                    return (
                      <Tabs.Tab key={`product-tab-${idx}`} label={tabLabel}>
                        <ProductDetail productDetailData={productDetail} />
                      </Tabs.Tab>
                    );
                  }
                )}
              </Tabs>
            ) : (
              <ProductDetail productDetailData={productsDetailCollection[0]} />
            )}
          </GridColumn>
        )}
        {/* product detail section rendered for smaller breakpoints */}
        {(viewport === 'sm' || viewport === 'xs') && (
          <GridColumn xs={12} sm={12} md={0} lg={0} xl={0}>
            <MobileProductDetail
              productsDetailCollection={productsDetailCollection}
              location={location}
            />
          </GridColumn>
        )}
      </GridRow>
    </Section>
  );
};

export const ProductDetailSectionFragment = graphql`
  fragment ProductDetailSectionFragment on ContentfulProductDetailSection {
    title
    productsDetailCollection {
      ...ProductDetailFragment
    }
  }
`;
