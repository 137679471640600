import React from 'react';
import { Section, Paragraph, Grid, H2, GallerySlider } from '@lsg/components';
import Card from './Card';
import { ContentAccessor } from '@lws/react-components';
import { graphql } from 'gatsby';
import { ContentfulCardsSection } from '../../generated/graphql-types';
import { GridRow } from '@lsg/components/lib/components/atoms/Grid/GridRow';
import { GridColumn } from '@lsg/components/lib/components/atoms/Grid/GridColumn';
import { SubSection } from '@lsg/components/lib/components/atoms/Section/_SubSection';
import { ContentfulRichTextRenderer } from '@lws/react-components/dist/components/RichTextRenderer';
import { createSimpleComponentRendererFor } from '@lws/react-components/dist/components/RichTextRenderer/renderers/util';
import {
  emptyDocument,
  useViewportSize,
} from '@lws/page-components/dist/utils';
import { BLOCKS } from '@contentful/rich-text-types';

interface CardsSectionProps {
  data: ContentAccessor<ContentfulCardsSection>;
}

const CardsSection = ({ data }: CardsSectionProps) => {
  const title = data.text('title');
  const description = data.richText('description', emptyDocument);
  const note = data.richText('note', emptyDocument);
  const cards = data.array('cards');
  const viewport = useViewportSize();
  return (
    <Section>
      <Grid>
        <GridRow gap={Grid.Spacing?.GALLERY}>
          <GridColumn md={12} lg={7} xl={7}>
            <H2>{title}</H2>
            <ContentfulRichTextRenderer content={description} />
          </GridColumn>
        </GridRow>
      </Grid>

      <SubSection id={'landing-products-cards'}>
        <Grid>
          <GridRow gap={Grid.Spacing?.CONTAINER}>
            <GridColumn xs={12} sm={12} md={12} lg={0} xl={0}>
              <GallerySlider
                slidesToDisplay={GallerySlider.SlidesToDisplay.NEUGELB}
                className={'landing-products-cards'}
              >
                {cards.map((teaser, idx) => (
                  <Card key={`card-${viewport}-${idx}`} data={teaser} />
                ))}
              </GallerySlider>
            </GridColumn>

            {cards.map((teaser, idx) => (
              <GridColumn
                key={`card-${idx}`}
                xs={0}
                sm={0}
                md={0}
                lg={4}
                xl={4}
              >
                <Card data={teaser} />
              </GridColumn>
            ))}
          </GridRow>
        </Grid>
      </SubSection>

      <Grid marginTop={Grid.Spacing?.HALF_SECTION}>
        <GridRow gap={Grid.Spacing?.GALLERY}>
          <GridColumn md={12} lg={7} xl={7}>
            <ContentfulRichTextRenderer
              content={note}
              options={{
                renderNode: {
                  [BLOCKS.PARAGRAPH]: createSimpleComponentRendererFor(
                    Paragraph,
                    {
                      size: Paragraph.Sizes?.HELPER,
                    }
                  ),
                },
              }}
            />
          </GridColumn>
        </GridRow>
      </Grid>
    </Section>
  );
};

export default CardsSection;

export const CardsSectionFragment = graphql`
  fragment CardsSectionFragment on ContentfulCardSection {
    __typename
    title
    description {
      json
    }
    cards {
      ...CardFragment
    }
    note {
      json
    }
  }
`;
