import React, { useMemo } from 'react';
import {
  Section,
  H2,
  Grid,
  Themes,
  Theme,
  Paragraph,
  Icon,
  H5,
} from '@lsg/components';
import { GridRow } from '@lsg/components/lib/components/atoms/Grid/GridRow';
import { GridColumn } from '@lsg/components/lib/components/atoms/Grid/GridColumn';
import styled from 'styled-components';

import {
  ContentfulRichTextRenderer,
  ContentfulRichtextRendererProps,
  LsgPicture,
  ContentAccessor,
} from '@lws/react-components';
import { emptyDocument } from '@lws/page-components/dist/utils';
import {
  LimitedWidth,
  DownloadGroup,
  DownloadItem,
  OverflowImage,
} from './StyledHelpers';

interface DownloadSectionProps {
  data: ContentAccessor<{
    title: string;
    text: Document;
    picture: any;
    downloads: {
      title: string;
      label: string;
      attachedFile?: {
        file: {
          url: string;
        };
      };
      attachmentLink?: string;
    }[];
  }>;
}

export const DownloadSection = ({ data }: DownloadSectionProps) => {
  const title = data.text('title');
  const text = data.richText('text', emptyDocument);
  const picture = data.asset('picture').fluid();
  const downloads = data.array('downloads');
  const twoColsDownloadsList = useMemo(() => {
    return (
      <DownloadGroup>
        {downloads.map((el, i) => {
          const title = el.text('title');
          const label = el.text('label');
          const attachedFileUrl = el
            .field('attachedFile')
            ?.field('file')
            ?.field('url')
            .raw();
          const attachmentLink = el.text('attachmentLink');
          return (
            <SeparatorBottom>
              <DownloadItem
                key={`download-item-${i}-${i}`}
                onClick={() =>
                  window.open(attachedFileUrl || attachmentLink, '_blank')
                }
              >
                <Icon
                  color={Icon.Colors.DEFAULT}
                  size={Icon.Sizes.CIRCLE}
                  name={Icon.Names.interaction___download}
                />
                <IconLinkCircleText>
                  <H5>{title}</H5>
                  <Paragraph size={Paragraph.Sizes?.HELPER}>{label}</Paragraph>
                </IconLinkCircleText>
              </DownloadItem>
            </SeparatorBottom>
          );
        })}
      </DownloadGroup>
    );
  }, [downloads]);

  return (
    <Theme look={Themes.DARK}>
      <Grid
        marginBottom={Grid.Spacing?.SECTION}
        marginTop={Grid.Spacing?.SECTION}
        verticalSpacing={Grid.Spacing?.SECTION}
      >
        <Section fullWidth>
          <GridRow>
            <GridColumn xs={0} sm={0} md={1} lg={1} xl={1} />
            <GridColumn xs={12} sm={8} md={5} lg={6} xl={6}>
              <H2>{title}</H2>
              {/* <LimitedWidth> */}
              <ContentfulRichTextRenderer
                content={text}
                options={
                  {
                    renderNode: {
                      p: Paragraph,
                    },
                  } as ContentfulRichtextRendererProps['options']
                }
              />
              {/* </LimitedWidth> */}
              {/* <Grid verticalSpacing={Grid.Spacing?.CONTAINER}> */}
              {twoColsDownloadsList}
              {/* </Grid> */}
            </GridColumn>

            <GridColumn
              xs={12}
              sm={4}
              md={6}
              lg={5}
              xl={5}
              verticalAlign={Grid.VerticalAlign?.MIDDLE}
            >
              <OverflowImage>
                <LsgPicture fluidData={picture} />
              </OverflowImage>
            </GridColumn>
          </GridRow>
        </Section>
      </Grid>
    </Theme>
  );
};

const IconLinkCircleText = styled.div`
  margin-left: 5px;
`;

const SeparatorBottom: any = styled.div`
  border-bottom: solid 1px #474747;
  display: flex;
  align-items: center;
  padding: 24px 0;
  &:last-child {
    border: none;
  }
`;
