import React from 'react';

export const WithLocation = (
  Component: React.ComponentType | React.SFC,
  location: any
) => {
  return (props: any) => {
    return <Component location={location} {...props} />;
  };
};
