import React from 'react';
import { IconLink, Icon, Section } from '@lsg/components';

export const WithIconLink = (
  Component: React.ComponentType | React.SFC,
  location: any
) => {
  return (props: any) => {
    return (
      <>
        {location.pathname !== '/' && (
          <Section separatorBottom className={'go-back-to-landing'}>
            <IconLink
              to={'/'}
              iconName={Icon.Names.interaction_arrows_chevronLeft}
            >
              Zurück zur Übersicht
            </IconLink>
          </Section>
        )}
        <Component {...props} />
      </>
    );
  };
};
