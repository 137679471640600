import * as React from 'react';
import styled from 'styled-components';
import { Section, Paragraph, H2, IconLink, Grid } from '@lsg/components';
import { GridRow } from '@lsg/components/lib/components/atoms/Grid/GridRow';
import { GridColumn } from '@lsg/components/lib/components/atoms/Grid/GridColumn';
import { ContentAccessor, LsgPicture } from '@lws/react-components';
import { ContentfulContactSection } from '../../generated/graphql-types';
import { graphql } from 'gatsby';
import { TIconNames } from '@lsg/components/lib/components/atoms/Icon/_generatedIcons';
import { useViewportSize } from '@lws/page-components/dist/utils';

interface ContactSectionProps {
  data: ContentAccessor<ContentfulContactSection>;
}

export const ContactSection = ({ data }: ContactSectionProps) => {
  const title = data.text('title');
  const textString = data.longText('text');
  const contactPersonName = data.text('contactPersonName');
  const contactPersonPosition = data.text('contactPersonPosition');
  const iconLinks = data.array('iconLinks');
  const image = data.image('image');
  const viewport = useViewportSize();
  return (
    <Section fullWidth={false}>
      <Grid>
        <GridRow columnVariate={false}>
          <GridColumn md={2} sm={0} xs={0} />
          <GridColumn xl={7} lg={8} md={8} sm={12} xs={12}>
            <H2>{title}</H2>
            <Paragraph>{textString}</Paragraph>
            <Grid verticalSpacing={Grid.Spacing?.CONTAINER}>
              <GridRow columnVariate={false} gap={Grid.Spacing?.CONTAINER}>
                <GridColumn xl={2} lg={3} md={3} sm={3} xs={4}>
                  <RoundPicture
                    key={`round-pic${viewport}`}
                    fluidData={image.fluid({ aspectRatio: '1:1' })}
                  />
                </GridColumn>
                <GridColumn
                  xl={8}
                  lg={7}
                  md={7}
                  sm={8}
                  xs={12}
                  verticalAlign={Grid.VerticalAlign?.MIDDLE}
                >
                  <Paragraph noMargin={true}>
                    <strong>{contactPersonName}</strong>
                  </Paragraph>
                  <Paragraph>{contactPersonPosition}</Paragraph>
                  {iconLinks &&
                    iconLinks.map((iconLink, idx) => {
                      const iconName = iconLink.text('iconName');
                      const text = iconLink.text('text', '');
                      const href = iconLink.text('href', '');
                      return (
                        <IconLinkWrapper key={`contact-link-${idx}`}>
                          <IconLink
                            iconName={iconName as TIconNames}
                            href={href}
                          >
                            {text}
                          </IconLink>
                        </IconLinkWrapper>
                      );
                    })}
                </GridColumn>
              </GridRow>
            </Grid>
          </GridColumn>
        </GridRow>
      </Grid>
    </Section>
  );
};

export const RoundPicture = styled(LsgPicture)`
  border-radius: 50%;
  overflow: hidden;
  min-width: 125px;
  max-width: 156px;
`;

const IconLinkWrapper = styled.div`
  margin-right: 24px;
  display: inline-block;
`;

export const ContactSectionFragment = graphql`
  fragment ContactSectionFragment on ContentfulContactSection {
    title
    text {
      text
    }
    contactPersonName
    contactPersonPosition
    iconLinks {
      ... on ContentfulIconLink {
        iconName
        text
        href
      }
    }
    image {
      ...ContentfulImageFragment
    }
  }
`;
