import React, { useEffect, useCallback } from 'react';
import styled from 'styled-components';
import {
  GallerySlider,
  IconLink,
  Icon,
  Layer,
  Paragraph,
  H4,
} from '@lsg/components';
import { ContentAccessor } from '@lws/react-components';
import { ContentfulProductDetail } from '../../generated/graphql-types';
import { ProductDetail } from './ProductDetail';

export const MobileProductDetail = ({
  productsDetailCollection,
  location,
}: {
  productsDetailCollection: ContentAccessor<ContentfulProductDetail>[];
  location: any;
}) => {
  const [layerProductIndex, openLayer] = React.useState<number | undefined>(
    undefined
  );

  const getProductIndex = useCallback(
    () =>
      window.location.hash.length
        ? productsDetailCollection.findIndex(
            el =>
              window.location.hash.replace('#', '') ===
              el.slug('productLocation')
          )
        : undefined,
    [productsDetailCollection]
  );

  const toggleLayer = useCallback((slug?: string) => {
    // if a slug is provided, opens the layer and add the hash to the url
    if (slug) {
      history.pushState('', document.title, `#${slug}`);
      return openLayer(getProductIndex());
    } else {
      // without a slug removes the hash to the url
      history.pushState('', document.title, `${location.pathname}`);
      return openLayer(undefined);
    }
  }, []);

  useEffect(() => {
    // on component mount opens the layer if an hash with the correct product slug is provided in the url
    openLayer(getProductIndex());
  }, []);

  return (
    <React.Fragment>
      {productsDetailCollection.length > 1 ? (
        <div id={'mobile-product-cards'}>
          <GallerySlider
            slidesToDisplay={GallerySlider.SlidesToDisplay.CONTENT_MD_2}
          >
            {productsDetailCollection.map((productDetail, idx) => (
              <Card
                key={`gallery-card-${idx}`}
                onClick={() =>
                  toggleLayer(productDetail.slug('productLocation'))
                }
              >
                <div>
                  <H4>{productDetail.text('title')}</H4>
                  <Paragraph>{productDetail.longText('text')}</Paragraph>
                </div>
                <IconLink
                  iconName={Icon.Names.interaction___add}
                  onClick={() =>
                    toggleLayer(productDetail.slug('productLocation'))
                  }
                >
                  Details anzeigen
                </IconLink>
              </Card>
            ))}
          </GallerySlider>
          <Layer
            id={'mobile-product-layer'}
            open={typeof layerProductIndex === 'number'}
            closeLinkLabel={'Zurück'}
            onCloseClick={() => toggleLayer()}
            htmlAttrs={{
              'aria-labelledby': 'layerLeftLabel',
              'aria-describedby': 'layerLeftDescription',
            }}
          >
            <ProductDetail
              productDetailData={
                productsDetailCollection[layerProductIndex || 0]
              }
            />
          </Layer>
        </div>
      ) : (
        <ProductDetail productDetailData={productsDetailCollection[0]} />
      )}
    </React.Fragment>
  );
};

const Card = styled.div`
  height: 260px;
  min-height: 260px;
  min-width: 240px;
  max-width: 260px;
  padding: 24px;
  box-shadow: 3px 10px 16px 0 rgba(193, 193, 193, 0.45);
  cursor: pointer;
  @media (hover: hover) {
    &:hover {
      transform: translateY(-8px);
      box-shadow: 3px 10px 40px 0 rgba(193, 193, 193, 0.45);
    }
  }
  transition: all 0.3s ease-in-out;
  button {
    margin-top: 24px;
  }
  border: solid 1px rgba(193, 193, 193, 0.15);
`;
