import React from 'react';

export const WithId = (
  Component: React.ComponentType | React.SFC,
  location: any
) => {
  return (props: any) => {
    return (
      <div id={location.pathname !== '/' ? 'product-page' : 'index-page'}>
        <Component {...props} />
      </div>
    );
  };
};
