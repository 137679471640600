import React from 'react';
import styled from 'styled-components';
import { H4, Paragraph, Button, Theme, Themes } from '@lsg/components';
import { ContentAccessor, BackgroundImage } from '@lws/react-components';
import { ContentfulCard } from '../../generated/graphql-types';
import { navigate } from 'gatsby';
import './styles.css';
import { createSimpleComponentRendererFor } from '@lws/react-components/dist/components/RichTextRenderer/renderers/util';
import { BLOCKS } from '@contentful/rich-text-types';
import { ContentfulRichTextRenderer } from '@lws/react-components/dist/components/RichTextRenderer';
import { emptyDocument } from '@lws/page-components/dist/utils';

interface CardProps {
  data: ContentAccessor<ContentfulCard>;
}

const Card = ({ data }: CardProps) => {
  const title = data.text('title');
  const label = data.text('label');
  const description = data.richText('description', emptyDocument);
  const ctaText = data.text('ctaText');
  const image = data.asset('image');
  const linkUrl = data.field('link').text('url');

  return (
    <CardWrapper onClick={() => navigate(`/${linkUrl}/`)}>
      <Theme look={Themes.DARK}>
        <CardHeader>
          <BackgroundImage data={image}>
            <BackgroundOverlay>
              <H4>{title}</H4>
              <Paragraph size={Paragraph.Sizes?.HELPER} noMargin>
                {label}
              </Paragraph>
            </BackgroundOverlay>
          </BackgroundImage>
        </CardHeader>
      </Theme>
      <CardBody>
        <ContentfulRichTextRenderer
          content={description}
          options={{
            renderNode: {
              [BLOCKS.PARAGRAPH]: createSimpleComponentRendererFor(Paragraph, {
                noMargin: true,
              }),
            },
          }}
        />
        <Button look={Button.Looks?.SECONDARY} to={`/${linkUrl}/`}>
          {ctaText}
        </Button>
      </CardBody>
    </CardWrapper>
  );
};

export default Card;

export const CardWrapper = styled.div`
  min-width: 250px;
  box-shadow: 3px 10px 16px 0 rgba(193, 193, 193, 0.45);
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  box-sizing: border-box;
  overflow: hidden;
  @media (hover: hover) {
    &:hover {
      transform: translate3d(0, -8px, 0);
      -moz-transform: translate3d(0, -8px, 0);
      -webkit-transform: translate3d(0, -8px, 0);
      box-shadow: 3px 10px 40px 0 rgba(193, 193, 193, 0.45);
    }
  }
  @media (max-width: 1195px) {
    margin-left: 12px;
    margin-right: 12px;
  }
`;

export const CardHeader = styled.div`
  height: 33% !important;
  width: 100%;
  min-height: 220px;
  max-height: 200px;
  p {
    color: #eaeaea !important;
  }
  /* without this the image background in the header doesn't cover correctly the space */
  div {
    div {
      width: 100%;
      height: 220px;
    }
  }
  @media (max-width: 1023px) {
    min-height: 200px;
    div {
      div {
        width: 100%;
        height: 200px;
      }
    }
  }
`;

export const BackgroundOverlay = styled.div`
  padding: 24px;
  box-sizing: border-box;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  h3,
  h4 {
    margin-bottom: 6px;
  }
`;

export const CardBody = styled.div`
  height: 390px;
  max-height: 460px;
  padding: 24px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;

  a {
    justify-self: flex-end;
    align-self: flex-start;
    margin: 0;
    flex-shrink: 0;
  }

  p {
    margin: 0 !important;
  }

  height: 400px;
  @media (min-width: 400px) {
    height: 332px;
  }
  @media (min-width: 620px) {
    height: 312px;
  }

  @media (min-width: 640px) {
    height: 332px;
  }

  @media (min-width: 1196px) {
    height: 412px;
  }

  @media (min-width: 1580px) {
    height: 332px;
  }
`;
