import * as React from 'react';
import { Section, H2, Accordion, AccordionGroup } from '@lsg/components';
import {
  ContentAccessor,
  ContentfulRichTextRenderer,
} from '@lws/react-components';
import { graphql } from 'gatsby';
import { ContentfulAccordionSection } from '../../generated/graphql-types';

interface AccordionSectionProps {
  data: ContentAccessor<ContentfulAccordionSection>;
}

export const AccordionSection = ({ data }: AccordionSectionProps) => {
  const title = data.text('title');
  const accordionElementsCollection = data.array('accordionElementsCollection');

  return (
    <Section>
      <H2>{title}</H2>
      <AccordionGroup.Stateful>
        {accordionElementsCollection &&
          accordionElementsCollection.map((accordionElement, idx) => {
            const text = accordionElement.richText('text');
            const title = accordionElement.text('title');

            return (
              title &&
              text && (
                <Accordion key={`accordion-element-${idx}`} title={title}>
                  <ContentfulRichTextRenderer content={text} />
                </Accordion>
              )
            );
          })}
      </AccordionGroup.Stateful>
    </Section>
  );
};

export const AccordionSectionFragment = graphql`
  fragment AccordionSectionFragment on ContentfulAccordionSection {
    title
    accordionElementsCollection {
      ... on ContentfulAccordionElement {
        title
        text {
          json
        }
      }
    }
  }
`;
