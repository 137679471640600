export const idToPdfs = {
  '2aea59fa-19aa-5bee-aabe-2a88fe706c66': [
    {
      title: 'Verkaufsprospekt Flexible Allocation Euroland I',
      label: null,
      attachedFile: {
        file: {
          url:
            'https://www.commerzbank.de/portal/media/a-30-sonstige-medien/pdf/themen/vermoegensverwaltung-1/Verkaufsprospekt_Flexible_Allocation_Euroland_I.pdf',
        },
      },
    },
    {
      title: 'Verkaufsprospekt Flexible Allocation Euroland R',
      label: null,
      attachedFile: {
        file: {
          url:
            'https://www.commerzbank.de/portal/media/a-30-sonstige-medien/pdf/themen/vermoegensverwaltung-1/Verkaufsprospekt_Flexible_Allocation_Euroland_R.pdf',
        },
      },
    },
    {
      title: 'Verkaufsprospekt Flexible Allocation USA I',
      label: null,
      attachedFile: {
        file: {
          url:
            'https://www.commerzbank.de/portal/media/a-30-sonstige-medien/pdf/themen/vermoegensverwaltung-1/Verkaufsprospekt_Flexible_Allocation_USA_I.pdf',
        },
      },
    },
    {
      title: 'Verkaufsprospekt Flexible Allocation USA R',
      label: null,
      attachedFile: {
        file: {
          url:
            'https://www.commerzbank.de/portal/media/a-30-sonstige-medien/pdf/themen/vermoegensverwaltung-1/Verkaufsprospekt_Flexible_Allocation_USA_R.pdf',
        },
      },
    },
    {
      title: 'Jahresbericht Flexible Allocation Euroland I',
      label: null,
      attachedFile: {
        file: {
          url:
            'https://www.commerzbank.de/portal/media/a-30-sonstige-medien/pdf/themen/vermoegensverwaltung-1/Jahresbericht_Flexible_Allocation_Euroland_I.pdf',
        },
      },
    },
    {
      title: 'Jahresbericht Flexible Allocation Euroland R',
      label: null,
      attachedFile: {
        file: {
          url:
            'https://www.commerzbank.de/portal/media/a-30-sonstige-medien/pdf/themen/vermoegensverwaltung-1/Jahresbericht_Flexible_Allocation_Euroland_R.pdf',
        },
      },
    },
    {
      title: 'Jahresbericht Flexible Allocation USA I',
      label: null,
      attachedFile: {
        file: {
          url:
            'https://www.commerzbank.de/portal/media/a-30-sonstige-medien/pdf/themen/vermoegensverwaltung-1/Jahresbericht_Flexible_Allocation_USA_I.pdf',
        },
      },
    },
    {
      title: 'Jahresbericht Flexible Allocation USA R',
      label: null,
      attachedFile: {
        file: {
          url:
            'https://www.commerzbank.de/portal/media/a-30-sonstige-medien/pdf/themen/vermoegensverwaltung-1/Jahresbericht_Flexible_Allocation_USA_R.pdf',
        },
      },
    },
    {
      title: 'Halbjahresbericht Flexible Allocation Euroland I',
      label: null,
      attachedFile: {
        file: {
          url:
            'https://www.commerzbank.de/portal/media/a-30-sonstige-medien/pdf/themen/vermoegensverwaltung-1/Halbjahresbericht_Flexible_Allocation_Euroland_I.pdf',
        },
      },
    },
    {
      title: 'Halbjahresbericht Flexible Allocation Euroland R',
      label: null,
      attachedFile: {
        file: {
          url:
            'https://www.commerzbank.de/portal/media/a-30-sonstige-medien/pdf/themen/vermoegensverwaltung-1/Halbjahresbericht_Flexible_Allocation_Euroland_R.pdf',
        },
      },
    },
    {
      title: 'Halbjahresbericht Flexible Allocation USA I',
      label: null,
      attachedFile: {
        file: {
          url:
            'https://www.commerzbank.de/portal/media/a-30-sonstige-medien/pdf/themen/vermoegensverwaltung-1/Halbjahresbericht_Flexible_Allocation_USA_I.pdf',
        },
      },
    },
    {
      title: 'Halbjahresbericht Flexible Allocation USA R',
      label: null,
      attachedFile: {
        file: {
          url:
            'https://www.commerzbank.de/portal/media/a-30-sonstige-medien/pdf/themen/vermoegensverwaltung-1/Halbjahresbericht_Flexible_Allocation_USA_R.pdf',
        },
      },
    },
    {
      title: 'Basisinformationsblatt Flexible Allocation Euroland I',
      label: null,
      attachedFile: {
        file: {
          url:
            'https://www.commerzbank.de/portal/media/a-30-sonstige-medien/pdf/themen/vermoegensverwaltung-1/Basisinformationsblatt_Flexible_Allocation_Euroland_I.pdf',
        },
      },
    },
    {
      title: 'Basisinformationsblatt Flexible Allocation Euroland R',
      label: null,
      attachedFile: {
        file: {
          url:
            'https://www.commerzbank.de/portal/media/a-30-sonstige-medien/pdf/themen/vermoegensverwaltung-1/Basisinformationsblatt_Flexible_Allocation_Euroland_R.pdf',
        },
      },
    },
    {
      title: 'Basisinformationsblatt Flexible Allocation USA I ',
      label: null,
      attachedFile: {
        file: {
          url:
            'https://www.commerzbank.de/portal/media/a-30-sonstige-medien/pdf/themen/vermoegensverwaltung-1/Basisinformationsblatt_Flexible_Allocation_USA_I.pdf',
        },
      },
    },
    {
      title: 'Basisinformationsblatt Flexible Allocation USA R',
      label: null,
      attachedFile: {
        file: {
          url:
            'https://www.commerzbank.de/portal/media/a-30-sonstige-medien/pdf/themen/vermoegensverwaltung-1/Basisinformationsblatt_Flexible_Allocation_USA_R.pdf',
        },
      },
    },
  ],
  '52d61d27-b0d4-5fc3-b9eb-44df07206a45': [
    {
      title: 'Verkaufsprospekt Aktienportfolio Covered Plus R',
      label: null,
      attachedFile: {
        file: {
          url:
            'https://www.commerzbank.de/portal/media/a-30-sonstige-medien/pdf/themen/vermoegensverwaltung-1/Verkaufsprospekt_Aktienportfolio_Covered_Plus_R.pdf',
        },
      },
    },
    {
      title: 'Jahresbericht Aktienportfolio Covered Plus R',
      label: null,
      attachedFile: {
        file: {
          url:
            'https://www.commerzbank.de/portal/media/a-30-sonstige-medien/pdf/themen/vermoegensverwaltung-1/Jahresbericht_Aktienportfolio_Covered_Plus_R.pdf',
        },
      },
    },
    {
      title: 'Halbjahresbericht Aktienportfolio Covered Plus R',
      label: null,
      attachedFile: {
        file: {
          url:
            'https://www.commerzbank.de/portal/media/a-30-sonstige-medien/pdf/themen/vermoegensverwaltung-1/Halbjahresbericht_Aktienportfolio_Covered_Plus_R.pdf',
        },
      },
    },
    {
      title: 'Basisinformationsblatt Aktienportfolio Covered Plus R ',
      label: null,
      attachedFile: {
        file: {
          url:
            'https://www.commerzbank.de/portal/media/a-30-sonstige-medien/pdf/themen/vermoegensverwaltung-1/Basisinformationsblatt_Aktienportfolio_Covered_Plus_R.pdf',
        },
      },
    },
  ],
  'fe4a089d-186a-50d0-9ac1-66f0c5ba9582': [
    {
      title: 'Verkaufsprospekt - Stiftungsfonds Stabilität A',
      label: null,
      attachedFile: {
        file: {
          url:
            'https://www.commerzbank.de/portal/media/a-30-sonstige-medien/pdf/themen/vermoegensverwaltung-1/Verkaufsprospekt_Stiftungsfonds_Stabilitaet_A.pdf',
        },
      },
    },
    {
      title: 'Verkaufsprospekt - Stiftungsfonds Stabilität E',
      label: null,
      attachedFile: {
        file: {
          url:
            'https://www.commerzbank.de/portal/media/a-30-sonstige-medien/pdf/themen/vermoegensverwaltung-1/Verkaufsprospekt_Stiftungsfonds_Stabilitaet_E.pdf',
        },
      },
    },
    {
      title: 'Verkaufsprospekt - Stiftungsfonds Rendite A',
      label: null,
      attachedFile: {
        file: {
          url:
            'https://www.commerzbank.de/portal/media/a-30-sonstige-medien/pdf/themen/vermoegensverwaltung-1/Verkaufsprospekt_Stiftungsfonds_Rendite_A.pdf',
        },
      },
    },
    {
      title: 'Verkaufsprospekt - Stiftungsfonds Rendite E',
      label: null,
      attachedFile: {
        file: {
          url:
            'https://www.commerzbank.de/portal/media/a-30-sonstige-medien/pdf/themen/vermoegensverwaltung-1/Verkaufsprospekt_Stiftungsfonds_Rendite_E.pdf',
        },
      },
    },
    {
      title: 'Jahresbericht Stiftungsfonds Stabilität A',
      label: null,
      attachedFile: {
        file: {
          url:
            'https://www.commerzbank.de/portal/media/a-30-sonstige-medien/pdf/themen/vermoegensverwaltung-1/Jahresbericht_Stiftungsfonds_Stabilitaet_A.pdf',
        },
      },
    },
    {
      title: 'Jahresbericht Stiftungsfonds Stabilität E',
      label: null,
      attachedFile: {
        file: {
          url:
            'https://www.commerzbank.de/portal/media/a-30-sonstige-medien/pdf/themen/vermoegensverwaltung-1/Jahresbericht_Stiftungsfonds_Stabilitaet_E.pdf',
        },
      },
    },
    {
      title: 'Halbjahresbericht Stiftungsfonds Stabilität A',
      label: null,
      attachedFile: {
        file: {
          url:
            'https://www.commerzbank.de/portal/media/a-30-sonstige-medien/pdf/themen/vermoegensverwaltung-1/Halbjahresbericht_Stiftungsfonds_Stabilitaet_A.pdf',
        },
      },
    },
    {
      title: 'Halbjahresbericht Stiftungsfonds Stabilität E',
      label: null,
      attachedFile: {
        file: {
          url:
            'https://www.commerzbank.de/portal/media/a-30-sonstige-medien/pdf/themen/vermoegensverwaltung-1/Halbjahresbericht_Stiftungsfonds_Stabilitaet_E.pdf',
        },
      },
    },
    {
      title: 'Jahresbericht Stiftungsfonds Rendite A',
      label: null,
      attachedFile: {
        file: {
          url:
            'https://www.commerzbank.de/portal/media/a-30-sonstige-medien/pdf/themen/vermoegensverwaltung-1/Jahresbericht_Stiftungsfonds_Rendite_A.pdf',
        },
      },
    },
    {
      title: 'Jahresbericht Stiftungsfonds Rendite E',
      label: null,
      attachedFile: {
        file: {
          url:
            'https://www.commerzbank.de/portal/media/a-30-sonstige-medien/pdf/themen/vermoegensverwaltung-1/Jahresbericht_Stiftungsfonds_Rendite_E.pdf',
        },
      },
    },
    {
      title: 'Halbjahresbericht Stiftungsfonds Rendite A',
      label: null,
      attachedFile: {
        file: {
          url:
            'https://www.commerzbank.de/portal/media/a-30-sonstige-medien/pdf/themen/vermoegensverwaltung-1/Halbjahresbericht_Stiftungsfonds_Rendite_A.pdf',
        },
      },
    },
    {
      title: 'Halbjahresbericht Stiftungsfonds Rendite E',
      label: null,
      attachedFile: {
        file: {
          url:
            'https://www.commerzbank.de/portal/media/a-30-sonstige-medien/pdf/themen/vermoegensverwaltung-1/Halbjahresbericht_Stiftungsfonds_Rendite_E.pdf',
        },
      },
    },
    {
      title: 'Basisinformationsblatt Stiftungsfonds Stabilität A ',
      label: null,
      attachedFile: {
        file: {
          url:
            'https://www.commerzbank.de/portal/media/a-30-sonstige-medien/pdf/themen/vermoegensverwaltung-1/Basisinformationsblatt_Stiftungsfonds_Stabilitaet_A.pdf',
        },
      },
    },
    {
      title: 'Basisinformationsblatt Stiftungsfonds Stabilität E  ',
      label: null,
      attachedFile: {
        file: {
          url:
            'https://www.commerzbank.de/portal/media/a-30-sonstige-medien/pdf/themen/vermoegensverwaltung-1/Basisinformationsblatt_Stiftungsfonds_Stabilitaet_E.pdf',
        },
      },
    },
    {
      title: 'Basisinformationsblatt Stiftungsfonds Rendite A ',
      label: null,
      attachedFile: {
        file: {
          url:
            'https://www.commerzbank.de/portal/media/a-30-sonstige-medien/pdf/themen/vermoegensverwaltung-1/Basisinformationsblatt_Stiftungsfonds_Rendite_A.pdf',
        },
      },
    },
    {
      title: 'Basisinformationsblatt Stiftungsfonds Rendite E ',
      label: null,
      attachedFile: {
        file: {
          url:
            'https://www.commerzbank.de/portal/media/a-30-sonstige-medien/pdf/themen/vermoegensverwaltung-1/Basisinformationsblatt_Stiftungsfonds_Rendite_E.pdf',
        },
      },
    },
  ],
};
