import styled from 'styled-components';

export const DownloadItem = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  flex-shrink: 0;
  min-width: 300px;
  &:hover {
    h5,
    p {
      /*transform: translateX(3px); */
    }
    span {
      box-shadow: inset 0 0 0 1px white !important;
      transition: all 0.3s ease-in-out;
    }
    svg {
      /*  transform: scale(1.1); */
      fill: white;
    }
  }
  h5,
  p {
    margin: 0 12px;
    transition: all 0.3s ease-in-out;
  }
  span {
    width: 48px;
    height: 48px;
  }
  svg {
    transition: all 0.3s ease-in-out;
  }
`;

export const DownloadGroup = styled.div`
  @media (min-width: 1196px) {
    width: 85%;
  }
`;

export const LimitedWidth = styled.div`
  /* width: 80%; */
  /* @media (max-width: 638px) {
    width: 100%;
  } */
  @media (min-width: 1622px) {
    width: 50%;
  }
  p {
    margin-bottom: 74px;
  }
`;

export const OverflowImage = styled.div`
  transform: translateX(16px);
  width: 200%;
  max-width: 800px;
  @media (max-width: 639px) {
    width: 100%;
    /* margin-top: 48px; */
    transform: translateX(0);
  }
  @media (min-width: 640px) and (max-width: 1023px) {
    transform: translateX(calc(100vw / 12));
    /* margin-top: 48px; */
  }
  @media (min-width: 1024px) {
    /* margin-top: 96px; */
    transform: translateX(calc((100vw / 12) * 2));
  }
  @media (min-width: 1195px) {
    /* margin-top: 96px; */
    max-width: 1000px;
    transform: translateX(0);
  }
`;
