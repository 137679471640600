//'https://www.commerzbank.de/marktdaten-public/data/mifid_chart.html';
const BASE_URL =
  process.env.NODE_ENV === 'development'
    ? '/marktdaten-public/data/mifid_chart.html'
    : '/marktdaten-public/data/';

export interface IMarketProps {
  imageSrc: string;
  label: string;
  tableTitle: string | null;
  rowData: string[][];
  footNotes: string[];
  assetName: string;
  date: string;
}

export async function getMarketCharts(
  productId: string
): Promise<IMarketProps> {
  try {
    // tightly coupled to html-Chart-Content - will throw on almost any change by comba
    // const data = await fetch(getURL('97676491')); // i.e. 248542721 or 97676491
    const data = await fetch(
      `${BASE_URL}?LANG=DE&ID_NOTATION=${productId}&TIMESPAN=5Y&LINK_INTERN=0&CHARTTYPE=LINE&SCALING=REL&SHOW_VOLUME=0&SHOW_PERFORMANCE=0`
    );
    const body = await data.text();
    const dom = new DOMParser();
    const htmlDoc = dom.parseFromString(body, 'text/html');
    const imageChart = htmlDoc.getElementById('idmscharts') as HTMLImageElement;
    const imageSrcURL = imageChart && imageChart.getAttribute('src');
    const imageSrc = `${BASE_URL}${imageSrcURL}`;
    // label
    const labelElem = htmlDoc.querySelector('.value-trend .value');
    const label = labelElem && labelElem.innerHTML.trim();
    if (!label || !label.length)
      throw new Error('ungültige Markt-Id: ' + productId);
    // date
    const dateElem = htmlDoc.querySelector('.date');
    const date = dateElem && dateElem.innerHTML.trim();
    if (!date || !date.length || date.indexOf('1970') !== -1)
      throw new Error('ungültiges Chart Datum');
    // footNotes
    const footnoteElems = htmlDoc.querySelectorAll('.fu-01');
    if (footnoteElems.length !== 2) throw new Error('ungültige Chart Notes');
    const footNotes = [
      footnoteElems[0].innerHTML.trim().replace('<sup>1</sup>', ''),
      footnoteElems[1].innerHTML.trim().replace('<sup>2</sup>', ''),
    ];
    // assetName - optional
    const assetName = htmlDoc
      .querySelectorAll('.show-tooltip')[0]
      .innerHTML.trim();

    const table = htmlDoc.getElementsByTagName('table')[0];
    const tHead = table.getElementsByTagName('thead')[0];
    const tBody = table.getElementsByTagName('tbody')[0];
    const titleElem = tHead.querySelector('.title');
    const tableTitle =
      titleElem && titleElem.innerHTML.trim().replace('<sup>2</sup>', '');

    let rowData = [],
      row = [],
      td,
      columns;
    const tableRows = tBody.getElementsByTagName('tr');

    for (var i = 0; i < tableRows.length; i++) {
      row = [];
      columns = tableRows[i].getElementsByTagName('td');
      if (columns.length !== 3) {
        throw new Error('Ungültige Anzahl an Marktdaten Spalten');
      }
      for (var c = 0; c < columns.length; c++) {
        td = columns[c].innerHTML.trim().replace('<sup>1</sup>', '¹');
        row.push(td);
      }
      rowData.push(row);
    }

    //console.log({ imageSrc, label, productId, tableTitle, rowData, footNotes });
    return {
      imageSrc,
      label,
      tableTitle,
      rowData,
      footNotes,
      assetName,
      date,
    };
  } catch (e) {
    //console.log(e.message);
    throw new Error('Problem im Marktdaten-Chart: ' + e.message);
  }
}
