import * as React from 'react';
import {
  H4,
  H3,
  Picture,
  H5,
  Paragraph,
  IconLink,
  Icon,
  Layer,
  Footnote,
} from '@lsg/components';
import styled from 'styled-components';

import { ComplexTable } from '@lsg/components/lib/components/molecules/ComplexTable/ComplexTable';
import { getMarketCharts, IMarketProps } from '../../utils/market-data-service';
import { GridColumn } from '@lsg/components/lib/components/atoms/Grid/GridColumn';
import { Spinner } from '../LoadingSpinner';
// prettier-ignore
const IMG_BASE = process.env.NODE_ENV === 'development' ? '/marktdaten-public' : 'https://www.commerzbank.de/marktdaten-public';

interface IMarketDataSectionProps {
  marketData: IMarketProps;
  productId: string;
  error: any;
}

const ImageLoadOrSpin = ({ imgSrc }: any) => {
  const [isImageLoading, setIsImageLoading] = React.useState(true);

  return (
    <>
      <img
        src={imgSrc}
        style={{ display: 'none' }}
        onLoad={() => setIsImageLoading(false)}
        onError={() => setIsImageLoading(false)}
      />
      <div style={{ display: isImageLoading ? 'block' : 'none' }}>
        <SpinnerWrapper>
          <Spinner />
        </SpinnerWrapper>
      </div>
      <div style={{ display: isImageLoading ? 'none' : 'block' }}>
        <Picture src={imgSrc} />
      </div>
    </>
  );
};

// prettier-ignore
export const MarketDataSection = ({ marketData, productId,  error, }: IMarketDataSectionProps) => {
  const [layerState, setLayerState] = React.useState(false);
  const imgSrc = `${IMG_BASE}/charts/ccb_open/chartmodule_new.chart?LANG=de&CHARTTYPE=LINE&HEIGHT=200&DATE_START=&DATE_END=&ID_NOTATION=${productId}&SHOW_VOLUME=0&LOGARITHMIC=0&TIME_SPAN=5Y&WIDTH=380&SCALING=rel&ID_NOTATION_COMP1=&ID_NOTATION_COMP2=&ID_NOTATION_COMP3=&ID_NOTATION_COMP4=&NO_DAYS_MA1=&NO_DAYS_MA2=&TYPE_IND1=&TYPE_IND2=&TYPE_IND3=&TYPE_IND4=&SCALE=1.5&LEGEND=&PDF=0&SHOW_CURRENCY=1&SHOW_PERFORMANCE=0&SHOW_PREV_CLOSE=0`;
  const toggleLayerState = React.useCallback(() => {
    const productLayer = document.getElementById('mobile-product-layer');
    setLayerState(!layerState);
    if (productLayer) {
      layerState
        ? (productLayer.style.overflow = 'scroll')
        : (productLayer.style.overflow = 'hidden');
    }
  }, [layerState]);

  if (error) {
    return (
      <>
        <H4>Entwicklung &amp; Outperformance</H4>
        <ErrorMessage>{error.message}</ErrorMessage>
      </>
    );
  }

  return (
    <Frame>
      <H4>Entwicklung &amp; Outperformance</H4>
      <IconLink
        iconName={Icon.Names.symbols___infoCircle}
        onClick={toggleLayerState}
      >
        Angaben zur Wertentwicklung
      </IconLink>
      <ImageWrapper>
        <H5>{marketData.label}</H5>
        <ImageLoadOrSpin imgSrc={imgSrc}/>
      </ImageWrapper>
      <Paragraph size={Paragraph.Sizes?.HELPER}>
        Angaben zur bisherigen Wertentwicklung erlauben keine verlässliche
        Prognose für die Zukunft.
      </Paragraph>
      <Layer

          closeLinkLabel={'Zurück zur Übersicht'}
          open={layerState}
          onCloseClick={toggleLayerState}
          htmlAttrs={{
            'aria-labelledby': 'layerLeftLabel',
            'aria-describedby': 'layerLeftDescription',
          }}
        >
          <H3>Wertentwicklung 5 Jahre</H3>
          <ImageWrapper>
            <H5>{marketData.label}</H5>
            <ImageLoadOrSpin imgSrc={imgSrc}/>
            <Paragraph size={Paragraph.Sizes?.HELPER}>
              {marketData.date}
            </Paragraph>
          </ImageWrapper>

          <DetailsTable
            rowData={marketData.rowData}
            tableTitle={marketData.tableTitle}
            footNotes={marketData.footNotes}
          />
        </Layer>
    </Frame>
  );
};

// prettier-ignore
const DetailsTable = ({ rowData, tableTitle, footNotes }: { rowData: string[][]; tableTitle: string|null; footNotes: string[]; }) => {
  return (
    <>
      <ComplexTable
        columnProperties={[
          { title: `${tableTitle}`, name: 'performance' },
          { title: `Anlage² in %`, name: 'anlage' },
          { title: 'in EUR', name: 'value' },
        ]}
        tableBodyData={rowData.map(entry => ({rowData: entry}))}
      />
      <Footnote>
        {footNotes.map((note, i) => (
          <Footnote.Item
            key={`footnotes-${i}`}
            identifier={String(i + 1)}
            id={`footnotes-${i}`}
          >
            {note}
          </Footnote.Item>
        ))}
      </Footnote>
      <Paragraph size={Paragraph.Sizes?.HELPER}>
        Angaben zur bisherigen Wertentwicklung erlauben keine verlässliche
        Prognose für die Zukunft.
      </Paragraph>
    </>
  );
};
const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  margin-top: 25px;
`;
export const Frame: any = styled.div`
  min-height: 200px;
  border-top: solid 1px #eaeaea;
  padding-top: 32px;
  padding-bottom: 32px !important;
`;

const ImageWrapper = styled.div`
  .lsg--picture {
    margin-bottom: 0 !important;
  }
  min-height: 350px;
  max-width: 600px;
  h5 {
    text-align: right;
    margin-bottom: 0;
  }
  p {
    text-align: right;
  }
`;
const ErrorMessage = styled(Paragraph)`
  color: #db1818;
`;
// if (isLoading || isImageLoading) {
//   return (
//     <GridWrap>
//       <H4>Entwicklung &amp; Outperformance</H4>
//       <img
//         style={{ display: 'none' }}
//         src={imgSrc}
//         onLoad={() => setIsImageLoading(false)}
//         onError={() => setIsImageLoading(false)}
//       />

//     </GridWrap>
//   );
// }
// const GridWrap = (props: any) => {
//   return (
//     <>
//       <GridColumn xs={12} sm={12} md={5} lg={5} xl={5}>
//         <Frame>{props.children}</Frame>
//       </GridColumn>
//       <GridColumn xs={0} sm={0} md={1} lg={1} xl={1} />
//     </>
//   );
// };

// const useAPI = ({ productId }: { productId: string }) => {
//   const [data, setData] = React.useState();
//   const [isLoading, setIsLoading] = React.useState<boolean>(true);
//   const [error, setError] = React.useState<any>(null);

//   const fetchData = async () => {
//     setError(null);
//     try {
//       setIsLoading(true);
//       const marketData = await getMarketCharts(productId);
//       setData(marketData);
//     } catch (e) {
//       setError(e);
//     } finally {
//       setIsLoading(false);
//     }
//   };

//   React.useEffect(() => {
//     fetchData();
//   }, []);

//   return [data, isLoading, error, fetchData];
// };
