import React, { Component } from 'react';
import { graphql } from 'gatsby';
import {
  StageComponent,
  QuoteComponent,
  SectionComponent,
  PageContentList,
  FactsComponent,
  BenefitsSection,
  CustomTeaserComponent,
} from '@lws/page-components';
import { Headline } from '@lsg/components';
import {
  StaticOrPreviewContent,
  ContentAccessor,
  GraphQLContentAccessor,
  ContentfulContentAccessor,
} from '@lws/react-components';

import {
  PageLayout,
  ProductDetailSection,
  AccordionSection,
  ContactSection,
} from '../../components';

import { ContentfulPage } from '../../generated/graphql-types';
import { getEntryByContentType } from '../../utils/contentful';
import { DownloadSection } from '../../components/DownloadSection/DownloadSection';
import CardsSection from '../../components/CardsSection/CardsSection';
import { WithIconLink } from '../../components/WithIconLink';
import { WithLocation } from '../../components/WithLocation';
import { WithId } from '../../components/WithId';
import { idToPdfs } from '../../pdfdata/pdfdata';
// import { CookieConsent } from '../../components/CookieConsent';
export interface PageProps {
  page: ContentAccessor<ContentfulPage>;
  location: any;
}

export interface PageTemplateProps {
  data: {
    contentfulPage: ContentfulPage;
  };
  pageContext: {
    title: string;
    url: string;
    contentful_id: string;
  };
  location: any;
}

function render(entry: PageProps) {
  const content = entry.page.array('content');
  const mappedSections = {
    ContentfulProductDetailSection: WithLocation(
      ProductDetailSection as React.ComponentType,
      entry.location
    ),
    ContentfulContactSection: WithIconLink(
      ContactSection as React.ComponentType,
      entry.location
    ),
    ContentfulAccordionSection: AccordionSection,
    ContentfulStage: WithId(
      StageComponent as React.ComponentType,
      entry.location
    ),
    ContentfulQuote: QuoteComponent,
    ContentfulDownloadSection: DownloadSection,
    ContentfulSection: SectionComponent,
    ContentfulFacts: FactsComponent,
    ContentfulBenefitsSection: BenefitsSection,
    ContentfulCustomTeaser: CustomTeaserComponent,
    ContentfulCardSection: CardsSection,
  };

  if (!content) {
    return <Headline>Error: No content found.</Headline>;
  }

  return <PageContentList content={content} componentMap={mappedSections} />;
}

class ProductTemplate extends Component<PageTemplateProps> {
  render() {
    // Overwrite pdfs. This should be done in contentful, but coba doesn't have access...
    const dataWithNewPdfs = overwritePdfs(this.props.data);

    const accessorData = new GraphQLContentAccessor(dataWithNewPdfs);

    const props: PageProps = {
      page: accessorData.field('contentfulPage'),
      location: this.props.location,
    };

    return (
      <PageLayout>
        <StaticOrPreviewContent
          render={render}
          staticData={props}
          getPreviewData={async () => {
            const page = await getEntryByContentType<ContentfulPage>(
              this.props.pageContext.contentful_id,
              'Page'
            );

            if (!page) return props;

            return {
              location,
              page: new ContentfulContentAccessor(page),
            } as PageProps;
          }}
        />
        {/* <CookieConsent /> */}
      </PageLayout>
    );
  }
}

export default ProductTemplate;

// query database to populate props.data
export const query = graphql`
  query($url: String!) {
    contentfulPage(url: { eq: $url }) {
      id
      content {
        ...PageContentListFragment
      }
      pageMetadata {
        ...SeoDataFragment
      }
    }
  }
`;

function overwritePdfs(props: {
  contentfulPage: ContentfulPage;
}): { contentfulPage: ContentfulPage } {
  const id = props.contentfulPage.id;
  if (idToPdfs[id]) {
    props.contentfulPage.content
      ?.filter(page => page?.__typename === 'ContentfulDownloadSection')
      .forEach((page: any) => {
        if (page?.downloads) {
          page.downloads = idToPdfs[id];
        }
      });
  }

  return props;
}
