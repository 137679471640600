import { createClient, ContentfulClientApi, Entry } from 'contentful';
import { useStaticQuery, graphql } from 'gatsby';
import { capitalize } from './string';

export interface GatsbyContenfulConfig {
  previewToken: string;
  spaceId: string;
  environmentId: string;
}

export interface GatsbySiteMetadataConfig {
  site: {
    siteMetadata: {
      contentful: GatsbyContenfulConfig;
    };
  };
}

export function getPreviewClient(
  config: GatsbyContenfulConfig
): ContentfulClientApi {
  return createClient({
    environment: config.environmentId,
    space: config.spaceId,
    accessToken: config.previewToken,
    host: 'preview.contentful.com',
  });
}

export async function getEntryByContentType<T>(
  id: string,
  contentType: string
): Promise<Entry<T> | null> {
  const gatsbyContentfulConfig = useStaticQuery<
    GatsbySiteMetadataConfig
  >(graphql`
    query {
      site {
        siteMetadata {
          contentful {
            previewToken
            spaceId
            environmentId
          }
        }
      }
    }
  `);

  if (
    !gatsbyContentfulConfig.site ||
    !gatsbyContentfulConfig.site.siteMetadata ||
    !gatsbyContentfulConfig.site.siteMetadata.contentful
  ) {
    return null;
  }

  const previewClient = getPreviewClient(
    gatsbyContentfulConfig.site.siteMetadata.contentful
  );

  const previewData = await previewClient.getEntries<T>({
    content_type: contentType,
    include: 10,
    'sys.id': id,
  });

  if (!previewData || !previewData.items || !(previewData.items.length > 0)) {
    return null;
  }

  return previewData.items[0];
}
