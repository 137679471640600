import Helmet from 'react-helmet';
import * as React from 'react';
import { ContentAccessor } from '@lws/react-components/dist/accessors';
import { ContentfulProductFigures } from '../generated/graphql-types';

type productData = {
  productFigures: Array<ContentAccessor<ContentfulProductFigures>>;
  productDescription: string | undefined | null;
};

export const GenerateFinancialProductJson = ({
  productFigures,
  productDescription,
}: productData) => (
  <Helmet>
    {productFigures &&
      productFigures.length > 0 &&
      productFigures.map((e, i) => (
        <script key={e.text('title', `${i}`)} type="application/ld+json">{`{
              "@context": "http://schema.org",
              "@type": "InvestmentFund",
              "name": "${e.text('title', '')}",
              "description": "${productDescription}",
              "identifier": {
                "@type": "PropertyValue",
                "propertyID": "WKN",
                "value": "${e.text('wkn', '')}"
              },
              "interestRate": "${e.text('preis', '')}"
             }`}</script>
      ))}
  </Helmet>
);

export const GenerateOrganizationJson = () => (
  <Helmet>
    <script type="application/ld+json">
      {`
      {
        "@context": "https://schema.org",
        "@type": "Corporation",
        "name": "Commerzbank Assetmanagement",
        "url": "https://www.am.commerzbank.de",
        "logo": "https://www.am.commerzbank.de/favicon.png",
        "contactPoint": {
          "@type": "ContactPoint",
          "telephone": "+49 69 13 64 51 51",
          "contactType": "customer service",
          "areaServed": ["DE"],
          "availableLanguage": ["de_DE"]
        }
      }
      `}
    </script>
  </Helmet>
);
