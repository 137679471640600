import * as React from 'react';
import { graphql } from 'gatsby';
import { H4, SimpleTable, H5 } from '@lsg/components';
import { ContentAccessor } from '@lws/react-components';
import { ContentfulProductFigures } from '../../generated/graphql-types';
import { Frame } from './MarketDataSection';

interface ProductTableProps {
  tableData: ContentAccessor<ContentfulProductFigures>;
}

const setTableSubline = (mindestanlagesumme: string) => {
  return mindestanlagesumme !== undefined &&
    mindestanlagesumme !== '–' &&
    mindestanlagesumme !== '-' &&
    mindestanlagesumme !== 'Nein'
    ? `${mindestanlagesumme} Mindesteinlagesumme`
    : `Keine Mindesteinlagesumme`;
};

export const ProductTable = ({ tableData }: ProductTableProps) => {
  const tableDataWithLabels = [
    { label: 'WKN', value: tableData.text('wkn') },
    { label: 'ISIN', value: tableData.text('isin') },
    { label: 'Volumen der Strategie', value: tableData.text('volumen') },
    { label: 'Währung', value: tableData.text('whrung') },
    {
      label: 'Ertragsanteil',
      value: tableData.text('preis'),
      helperText: 'orientiert am Ausgabeaufschlag',
    },
    {
      label: 'Verwaltungsvergütung',
      value: tableData.text('verwaltungsvergtung'),
    },
    {
      label: 'Performance-Vergütung',
      value: tableData.text('performancevergtung'),
    },
    {
      label: 'Sparplanfähig',
      value: tableData.text('sparplanfhig'),
    },
    {
      label: 'Laufende Kosten',
      value: tableData.text('laufendeKosten'),
    },
    {
      label: 'Fondsauflagedatum',
      value: tableData.text('fondsauflagedatum'),
    },
  ];

  return (
    <Frame>
      <H4> {tableData.text('title')} </H4>
      <H5>{setTableSubline(tableData.text('mindestanlagesumme'))}</H5>
      <SimpleTable>
        {tableDataWithLabels.map(({ label, value, helperText }, idx) => {
          return (
            value !== undefined && (
              <SimpleTable.Row
                key={`table-row-${value}-${idx}`}
                title={label}
                helperText={helperText}
              >
                {value}
              </SimpleTable.Row>
            )
          );
        })}
      </SimpleTable>
    </Frame>
  );
};

export const ProductFiguresFrag = graphql`
  fragment ProductFiguresFragment on ContentfulProductFigures {
    title
    mindestanlagesumme
    wkn
    isin
    whrung
    preis
    verwaltungsvergtung
    performancevergtung
    sparplanfhig
  }
`;
