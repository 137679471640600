import { graphql } from 'gatsby';
import * as React from 'react';
import { BLOCKS } from '@contentful/rich-text-types';
import { H3, Paragraph, Section } from '@lsg/components';
import { GridColumn } from '@lsg/components/lib/components/atoms/Grid/GridColumn';
import { GridRow } from '@lsg/components/lib/components/atoms/Grid/GridRow';
import { ContentAccessor } from '@lws/react-components';
import { ContentfulProductFigures } from '../../generated/graphql-types';
import { GenerateFinancialProductJson } from '../../utils/jsonLdGenerator';
import { ContentfulProductDetail } from '../../generated/graphql-types';

import { createSimpleComponentRendererFor } from '@lws/react-components/dist/components/RichTextRenderer/renderers/util';
import { ProductTable } from './ProductTable';
import { ContentfulRichTextRenderer } from '@lws/react-components/dist/components/RichTextRenderer';
import { ProductRiskAndChartWrapper } from './ProductRiskAndChartWrapper';

const { Fragment } = React;
interface ProductDetailProps {
  productDetailData: ContentAccessor<ContentfulProductDetail>;
}

export const ProductDetail = ({ productDetailData }: ProductDetailProps) => {
  const title = productDetailData.text('title');
  const text = productDetailData.longText('text');
  const productId = productDetailData.text('productId');
  const risk = productDetailData.number('risk');
  const table = productDetailData.array('table');
  const footnotes = productDetailData.richText('footnotes');

  return (
    <Fragment>
      <Section fullWidth={true}>
        <GridRow>
          <GridColumn xs={10} sm={10} md={8} lg={8} xl={7}>
            <H3>{title}</H3>
            <Paragraph>{text}</Paragraph>
          </GridColumn>
        </GridRow>
      </Section>
      <Section fullWidth={true}>
        <GridRow>
          {productId && risk && (
            <ProductRiskAndChartWrapper {...{ productId, risk }} />
          )}
        </GridRow>
        <GridRow>
          {table.length > 0 &&
            table.map((t: ContentAccessor<ContentfulProductFigures>, idx) => {
              return (
                <React.Fragment key={`table-${idx}`}>
                  <GridColumn xs={12} sm={12} md={5} lg={5} xl={5}>
                    <ProductTable tableData={t} />
                  </GridColumn>
                  <GridColumn xs={0} sm={0} md={1} lg={1} xl={1} />
                </React.Fragment>
              );
            })}
          {footnotes && (
            <ContentfulRichTextRenderer
              content={footnotes}
              options={{
                renderNode: {
                  [BLOCKS.PARAGRAPH]: createSimpleComponentRendererFor(
                    Paragraph,
                    {
                      size: Paragraph.Sizes?.HELPER,
                    }
                  ),
                },
              }}
            />
          )}
        </GridRow>
      </Section>
      <GenerateFinancialProductJson
        productFigures={table}
        productDescription={text}
      />
    </Fragment>
  );
};

export const ProductDetailFrag = graphql`
  fragment ProductDetailFragment on ContentfulProductDetail {
    title
    productId
    text {
      text
    }
    productLocation
    risk
    table {
      ...ProductFiguresFragment
    }
    footnotes {
      json
    }
  }
`;
