import * as React from 'react';
import { getMarketCharts } from '../../utils/market-data-service';
import { GridColumn } from '@lsg/components/lib/components/atoms/Grid/GridColumn';
import { RiskIndicator } from './RiskIndicator';
import { MarketDataSection } from './MarketDataSection';
import { Spinner } from '../LoadingSpinner';
import styled from 'styled-components';

const useAPI = ({ productId }: { productId: string }) => {
  const [data, setData] = React.useState();
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [error, setError] = React.useState<any>(null);

  const fetchData = async () => {
    setError(null);
    try {
      setIsLoading(true);
      const marketData = await getMarketCharts(productId);
      setData(marketData);
    } catch (e) {
      setError(e);
    } finally {
      setIsLoading(false);
    }
  };

  React.useEffect(() => {
    fetchData();
  }, []);

  return [data, isLoading, error, fetchData];
};

// prettier-ignore
export const ProductRiskAndChartWrapper = ({ productId, risk }: { productId: string; risk: number; }) => {
  // productId = '97676491';  // enough of data
  // productId = '248542721'; // not enough data
  const [marketData, isLoading, error] = useAPI({ productId });

  if (isLoading) {
    return (
      <>
        <GridColumn xs={12} sm={12} md={11} lg={11} xl={11}>
          <Frame>
            <SpinnerWrapper>
              <Spinner />
            </SpinnerWrapper>
          </Frame>
        </GridColumn>
      </>
    );
  }
  return (
    <>
      {marketData && marketData.rowData && marketData.rowData.length > 2 && (
        <>
          <GridColumn xs={12} sm={12} md={5} lg={5} xl={5}>
            <MarketDataSection {...{ marketData, error, productId }} />
          </GridColumn>
          <GridColumn xs={0} sm={0} md={1} lg={1} xl={1} />
        </>
      )}
      <GridColumn xs={12} sm={12} md={5} lg={5} xl={5}>
        <RiskIndicator riskValue={risk} />
      </GridColumn>
    </>
  );
};

const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  margin-top: 25px;
`;
export const Frame: any = styled.div`
  min-height: 200px;
  border-top: solid 1px #eaeaea;
  padding-top: 32px;
  padding-bottom: 32px !important;
`;
