import * as React from 'react';
import styled from 'styled-components';
import { H4, Paragraph, H5 } from '@lsg/components';
import { Frame } from './MarketDataSection';

export const RiskIndicator = ({ riskValue }: { riskValue: number }) => (
  <Frame>
    <H4>Risikoeignung</H4>
    <NumberLabels>
      {[1, 2, 3, 4, 5, 6, 7].map(el =>
        el === riskValue ? (
          <H5 key={`risk-label-${el}`}>{el}</H5>
        ) : (
          <Paragraph
            size={Paragraph.Sizes && Paragraph.Sizes.HELPER}
            key={`risk-label-${el}`}
          >
            {el}
          </Paragraph>
        )
      )}
    </NumberLabels>

    <IndicatorBar riskValue={riskValue} />
    <Flex>
      <Paragraph size={Paragraph.Sizes && Paragraph.Sizes.HELPER}>
        Geringeres Risiko
      </Paragraph>
      <Paragraph size={Paragraph.Sizes && Paragraph.Sizes.HELPER}>
        Höheres Risiko
      </Paragraph>
    </Flex>
  </Frame>
);

const IndicatorBar = styled.div`
  height: 8px;
  width: 100%;
  background: #d8d8d8;
  position: relative;
  &::before {
    content: '';
    position: absolute;
    height: 8px;
    width: ${({ riskValue }: { riskValue: number }) =>
      `${((riskValue - 1) * 100) / 6}%`};
    top: 0;
    left: 0;
    background: #ffcc33;
  }
`;

const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 9px;
`;

const NumberLabels = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  width: 100%;
  margin-top: 38px;
`;
